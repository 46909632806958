<script setup>
import { defineEmits, defineProps } from 'vue'

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    show: {
        type: Boolean,
        required: true,
        default: false,
    },
})

const emit = defineEmits(['update', 'close'])

const closePopup = () => {
    emit('close')
}
</script>

<template>
    <div
        v-if="show"
        class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
        <div class="bg-white p-6 rounded-lg mb-2">
            <div class="flex justify-between items-center">
                <h1 class="text-xl font-bold">{{ title }}</h1>
                <button
                    @click="closePopup"
                    class="p-2 m-2 bg-red-500 text-white rounded"
                >
                    Lukk
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<style scoped></style>

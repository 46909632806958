import axios from 'axios'
const api_url = import.meta.env.VITE_API_URL

export async function logIn(email, password) {
    try {
        const response = await axios.post(
            api_url + '/authentication/login',
            {
                email: email,
                password: password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        )
        return response
    } catch (e) {
        console.error('Error:', e)
    }
}

export async function signUp(email, password, name) {
    try {
        const response = await axios.post(
            api_url + '/authentication/signup',
            {
                email: email,
                password: password,
                username: name,
                settingsAsJson: '{testing:red}',
            },
            { headers: { 'Content-Type': 'application/json' } }
        )

        return response.data
    } catch (e) {
        console.error('Error:', e)
        console.log(e.status)
    }
}

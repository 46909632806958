<script setup>
import { watchEffect } from 'vue'
import confetti from 'canvas-confetti'

const props = defineProps({
    showConfetti: Boolean,
})
const defaults = {
    startVelocity: 30,
    spread: 360,
    ticks: 200,
    zIndex: 50,
    shapes: ['star', 'circle', 'square'],
}

function generateConfetti() {
    console.log('Generating confetti')
    let particleCount = 500

    confetti(
        Object.assign({}, defaults, {
            particleCount,
            origin: {
                x: randomInRange(0.25, 0.5),
                y: randomInRange(0.6, 0, 7),
            },
        })
    )
    confetti(
        Object.assign({}, defaults, {
            particleCount,
            origin: {
                x: randomInRange(0.25, 0.5),
                y: randomInRange(0.6, 0, 7),
            },
        })
    )
    confetti(
        Object.assign({}, defaults, {
            particleCount,
            origin: {
                x: randomInRange(0.5, 0.75),
                y: randomInRange(0.6, 0, 7),
            },
        })
    )
    confetti(
        Object.assign({}, defaults, {
            particleCount,
            origin: {
                x: randomInRange(0.5, 0.75),
                y: randomInRange(0.6, 0, 7),
            },
        })
    )
}

function randomInRange(min, max) {
    return Math.random() * (max - min) + min
}

watchEffect(() => {
    if (props.showConfetti) {
        generateConfetti()
    } else {
        confetti.reset()
    }
})
</script>

<template></template>

<style scoped></style>

<script setup>
import DashboardIcon from '@/assets/dashboard.svg'
import LogoutIcon from '@/assets/logout.svg'
import GroupIcon from '@/assets/group.svg'
import BadgeIcon from '@/assets/badge.svg'
import ProfileIcon from '@/assets/profile.svg'
import { logOut } from '@/utils/logOut.js'
import { useTokenStore } from '@/stores/tokenStore.js'

import { computed, ref } from 'vue'
import router from '@/router/index.js'
import { useSidebarStore } from '@/stores/sidebarStore.js'
import { useRoute } from 'vue-router'

const emit = defineEmits('displayFirstNotif')

const activeMenu = ref('dashboard')

const sidebarStore = useSidebarStore()

const isSidebarOpen = computed(() => sidebarStore.isSidebarOpen)

const tokenStore = useTokenStore()

const route = useRoute()

const menus = [
    {
        name: 'Dashboard',
        icon: DashboardIcon,
        route: '/new-dash',
        alt: 'dashboard icon',
    },
    {
        name: 'Group',
        icon: GroupIcon,
        route: '/group-saving-goal',
        alt: 'group icon',
    },
    //{name: 'Notifications', icon: NotificationIcon, alt: 'notification icon'},
    {
        name: 'Achievements',
        icon: BadgeIcon,
        route: '/achievements',
        alt: 'achievements icon',
    },
    {
        name: 'Profile',
        icon: ProfileIcon,
        route: '/profile',
        alt: 'profile icon',
    },
    {
        name: 'Logout',
        icon: LogoutIcon,
        alt: 'logout icon',
        click: logOut,
        to: { name: 'login' },
    },
]

const handleClick = (menu) => {
    if (menu.click) {
        menu.click()
    } else if (menu.route) {
        router.push(menu.route)
    }
    activeMenu.value = menu.name
}
</script>

<template>
    <div
        class="flex flex-col gap-y-4 items-center py-8 w-24 bg-midnight min-h-lvh transition-duration-500"
        v-if="isSidebarOpen && tokenStore.loggedIn"
    >
        <div class="flex flex-col gap-y-4 items-end self-end">
            <div
                v-for="menu in menus"
                :key="menu.name"
                :class="
                    route.path === menu.route
                        ? 'bg-midnightSky rounded-l-xl relative before:absolute before:w-4 before:h-8 before:-top-8 before:rounded-br-xl before:right-0 before:shadow-inverse-top after:absolute after:w-4 after:h-8 after:-bottom-8 after:rounded-tr-xl after:right-0 after:shadow-inverse-bottom'
                        : ''
                "
            >
                <button
                    class="p-4 my-4 mr-4 ml-3 rounded-xl"
                    @click="handleClick(menu)"
                    :class="
                        route.path === menu.route
                            ? 'text-white shadow-primary bg-primary'
                            : 'text-primary'
                    "
                >
                    <component
                        :is="menu.icon"
                        class="w-6 h-6 fill-current"
                        :class="menu.name === 'Logout' ? 'text-red-500' : ''"
                    />
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script setup>
import { ref, onMounted, computed } from 'vue'
import PopupComponent from '../generic/PopupComponent.vue'
import ConfirmButton from '../buttons/ConfirmButton.vue'
import { createChallenge, getRecommendation } from '@/utils/ChallengeService.js'
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css' // you can customize the style of the picker by importing the css file
import router from '@/router/index.js'
import PlusButton from '@/components/buttons/PlusButton.vue'
import { useChallengesStore } from '@/stores/challengeStore.js'
import { useSavingGoalStore } from '@/stores/savingGoalStore'
import { useGroupSavingGoalStore } from '@/stores/groupSavingGoalStore.js'

const props = defineProps({
    isGroupChallenge: {
        type: Boolean,
        default: true,
    },
})
const showPopup = ref(false)

const openPopup = () => {
    showPopup.value = true
}

const closePopup = () => {
    showPopup.value = false
}

const savingGoalId = ref('')
const name = ref('')
const icon = ref('')
const amount = ref('')
const amtDays = ref('')

const challengeStore = useChallengesStore()
const savingGoalStore = useSavingGoalStore()
const groupSavingGoalStore = useGroupSavingGoalStore()

const savingGoals = computed(() => {
    if (props.isGroupChallenge) {
        return savingGoalStore.savingGoals.concat(
            savingGoalStore.groupSavingGoals
        )
    }
    return savingGoalStore.savingGoals
})

const createChall = async () => {
    try {
        await createChallenge(
            savingGoalId.value,
            name.value,
            icon.value,
            amount.value,
            amtDays.value
        )
        await challengeStore.fetchChallenges()
        await savingGoalStore.fetchSavingGoals()
        closePopup()
    } catch (error) {
        console.error('Failed to create challenge: ', error)
    }
}

onMounted(async () => {
    await groupSavingGoalStore.fetchGroupSavingGoals()
    await savingGoalStore.fetchSavingGoals()
})

function onSelectEmoji(emoji) {
    icon.value = emoji.i
}

async function generateChallenge() {
    if (savingGoalId.value) {
        let generateChallenge = await getRecommendation(savingGoalId.value)
        amount.value = generateChallenge.amount
        amtDays.value = generateChallenge.amtDays
        name.value = generateChallenge.description
        icon.value = generateChallenge.icon
        document.querySelector('.v3-emoji-picker-input').value =
            await generateChallenge.icon
    }
}
</script>

<template>
    <PlusButton @click="openPopup" />
    <PopupComponent
        :title="'Create challenge'"
        :show="showPopup"
        @close="closePopup"
    >
        <div>
            <form @submit.prevent="createChall" class="flex flex-col space-y-5">
                <label class="flex justify-between text-wrap text-midnight">
                    Sparemål:
                    <select
                        v-model="savingGoalId"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    >
                        <option
                            v-for="savingGoal in savingGoals"
                            :key="savingGoal.savingId"
                            :value="savingGoal.savingId"
                        >
                            {{ savingGoal.name }}
                        </option>
                    </select>
                </label>
                <label class="flex justify-between text-wrap text-midnight">
                    Challenge navn:
                    <input
                        v-model="name"
                        type="text"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label
                    class="flex justify-between text-wrap text-midnight"
                    for="challengeIcon"
                >
                    Challenge Icon:
                    <EmojiPicker
                        id="challengeIcon"
                        :pickerType="'input'"
                        v-model="icon"
                        @select="onSelectEmoji"
                        class="p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between text-wrap text-midnight">
                    Challenge Amount:
                    <input
                        v-model="amount"
                        type="number"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between text-wrap text-midnight">
                    Amount of days:
                    <input
                        v-model="amtDays"
                        type="number"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <button
                    class="active:scale-95 bg-gradient-to-tr content-center bg-midnightSky rounded-xl font-medium px-6 py-2 text-sky shadow-md"
                    type="button"
                    @click="generateChallenge"
                >
                    Generer med KI
                </button>
                <button
                    class="active:scale-95 bg-gradient-to-tr content-center bg-midnightSky rounded-xl font-medium px-6 py-2 text-sky shadow-md"
                    type="submit"
                >
                    Lag Challenge
                </button>
            </form>
        </div>
    </PopupComponent>
</template>

<style scoped></style>

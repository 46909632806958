import { useTokenStore } from '@/stores/tokenStore.js'
import axios from 'axios'
import { logOut } from '@/utils/logOut.js'
const api_url = import.meta.env.VITE_API_URL

export async function createAnswers(map) {
    const tokenStore = useTokenStore()

    try {
        const response = await axios.post(
            api_url + '/answers',
            { answers: map },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}
export async function updateAnswers(map) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.put(
            api_url + '/answers',
            { answers: map },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}
export async function getAnswers() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/answers', {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${tokenStore.jwtToken}`,
            },
        })
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

<script setup>
import { ref } from 'vue'
import { useTokenStore } from '@/stores/tokenStore.js'
import { signUp } from '@/utils/AuthenticationService.js'
import router from '@/router/index.js'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import FooterUrl from '@/assets/footer_image.svg?url'

const name = ref('')
const email = ref('')
const password = ref('')
const store = useTokenStore()

async function signup() {
    let response = await signUp(email.value, password.value, name.value)
    if (response) {
        store.saveToken(response)
        store.saveEmail(email.value)
        store.saveUsername(name.value)
        console.log(store.jwtToken)
        await router.push({ path: '/questions' })
    } else {
        alert('signup failed')
    }
}
</script>

<template>
    <div class="flex flex-col items-center z-10">
        <h1 class="signup-text">Bli med på Safarien!</h1>
        <form action="" method="get" id="form" @submit.prevent="signup()">
            <div>
                <label for="name" class="text-sky">Navn</label>
                <input
                    type="text"
                    name="name"
                    id="name"
                    class="input-component"
                    required
                    v-model="name"
                />
            </div>
            <div>
                <label for="email" class="text-sky">Mail</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    class="input-component"
                    required
                    v-model="email"
                />
            </div>
            <div>
                <label for="password" class="text-sky">Passord</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    class="input-component"
                    required
                    v-model="password"
                />
            </div>
            <!--div >
                <input type="submit" value="Sign up" />
            </div-->
            <input
                class="w-full my-3 active:scale-95 bg-gradient-to-tr content-center from-sand to-sky rounded-xl font-medium px-6 py-2 text-midnight shadow-md"
                type="submit"
                value="Registrer"
            />
        </form>
    </div>
    <div class="p-10 m-7 z-0">
        <img
            class="absolute bottom-0 left-0 w-full h-auto opacity-10"
            :src="FooterUrl"
            alt="Footer Image"
        />
    </div>
</template>

<style scoped>
.input-component {
    display: block;
    border-radius: 0.5em;
    border: black solid 0.1em;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

.signup-text {
    --bg-size: 400%;
    --color-one: hsl(78, 48%, 62%);
    --color-two: hsl(78, 33%, 41%);
    font-size: clamp(1rem, 11vw, 10rem);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
    background: linear-gradient(
            90deg,
            var(--color-one),
            var(--color-two),
            var(--color-one)
        )
        0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: move-bg 8s infinite linear;
}

@media (prefers-reduced-motion: no-preference) {
    .signup-text {
        animation: move-bg 8s linear infinite;
    }
    @keyframes move-bg {
        to {
            background-position: var(--bg-size) 0;
        }
    }
}
</style>

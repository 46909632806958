import { defineStore } from 'pinia'

export const useSidebarStore = defineStore('sidebar', {
    state: () => ({
        isSidebarOpen: window.innerWidth > 640, // Set the initial state based on the screen size
        isMobile: window.innerWidth <= 640,
    }),
    actions: {
        toggleSidebar() {
            if (this.isMobile) {
                this.isSidebarOpen = !this.isSidebarOpen
            } else {
                this.isSidebarOpen = true // Always keep the sidebar open on larger screens
            }
        },
        checkIsMobile() {
            this.isMobile = window.innerWidth <= 640
            if (!this.isMobile) {
                this.isSidebarOpen = true // Ensure the sidebar is open when switching to a larger screen
            } else {
                this.isSidebarOpen = false // Ensure the sidebar is closed when switching to a smaller screen
            }
        },
        closeSidebar() {
            this.isSidebarOpen = false
        },
        openSidebar() {
            this.isSidebarOpen = true
        },
    },
})

<script setup>
import { RouterLink } from 'vue-router'

const props = defineProps({
    to: {
        type: Object,
        required: true,
    },
})
</script>

<template>
    <RouterLink
        v-slot="{ navigate }"
        class="dropdown-button flex w-full justify-center flex-col items-center bg-foliage h-[10vh] border-b border-gray-300"
        :to="props.to"
    >
        <button
            id="{{ props.to }}"
            @click="navigate"
            class="justify-center w-full"
        >
            <slot></slot>
        </button>
    </RouterLink>
</template>

<style scoped></style>

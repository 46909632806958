<script setup>
import { ref } from 'vue'

const props = defineProps({
    label: String,
    type: String,
    options: Array,
    modelValue: [String, Boolean, Number],
})

const emits = defineEmits(['update:modelValue'])

const selectedValue = ref(props.modelValue)

const updateValue = (value) => {
    selectedValue.value = value
    emits('update:modelValue', value)
}
</script>

<template>
    <div
        class="text-sky flex-col items-center py-4 boarder-white rounded-lg w-full"
    >
        <label class="text-sm font-bold my-4">{{ label }}</label>
        <div v-if="type === 'toggle'">
            <button
                @click="updateValue(!selectedValue)"
                :class="{
                    'bg-green-500': selectedValue,
                    'bg-midnight opacity-50': !selectedValue,
                }"
                class="w-full p-2 rounded"
            >
                {{ selectedValue ? 'Enabled' : 'Disabled' }}
            </button>
        </div>
        <div v-else-if="type === 'select'">
            <select
                @change="updateValue($event.target.value)"
                :value="selectedValue"
                class="w-full p-2 rounded bg-midnight"
            >
                <option v-for="option in options" :value="option.value">
                    {{ option.label }}
                </option>
            </select>
        </div>
    </div>
</template>

<style scoped></style>

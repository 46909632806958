import { defineStore } from 'pinia'
import { getNotifications } from '../utils/UserService'

export const useNotificationStore = defineStore('NotificationStore', {
    state: () => {
        return {
            notifications: [],
            notifications_len: 0,
        }
    },

    actions: {
        async fetchNotifications() {
            try {
                let newNotifs = await getNotifications()
                console.log('newNotifs: ', newNotifs)

                for (let i = 0; i < newNotifs.length; i++) {
                    this.notifications.push(newNotifs[0])
                }

                this.notifications_len = this.notifications.length
                console.log('notifs in state: ', this.notifications)
            } catch (err) {
                console.error(err)
            }
        },
        popFirst() {
            if (this.notifications_len === 0) return null

            let notif = this.notifications[0]
            console.log('first notif: ', notif)

            if (this.notifications_len === 1) {
                this.notifications = []
                this.notifications_len = 0
                return notif
            }

            // Pop first
            this.notifications = this.notifications.slice(1)
            this.notifications_len = this.notifications.length
            return notif
        },
    },
    persist: {
        storage: sessionStorage, // note that data in sessionStorage is cleared when the page session ends
    },
})

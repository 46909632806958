<script setup>
import { ref } from 'vue'
import PopupComponent from '../generic/PopupComponent.vue'
import ConfirmButton from '../buttons/ConfirmButton.vue'
import { createSavingGoal } from '@/utils/SavingGoalService.js'
import PlusButton from '@/components/buttons/PlusButton.vue'
import { useSavingGoalStore } from '@/stores/savingGoalStore'
import { useNotificationStore } from '../../stores/notificationStore'

const showPopup = ref(false)
const savingGoalStore = useSavingGoalStore()
const notificationStore = useNotificationStore()

const openPopup = () => {
    showPopup.value = true
}

const closePopup = () => {
    showPopup.value = false
}

const name = ref('')
const goalAmount = ref('')
const targetDate = ref('')

const createGoal = async () => {
    try {
        await createSavingGoal(name.value, goalAmount.value, targetDate.value)
        console.log(
            `Creating goal with name: ${name.value}, amount: ${goalAmount.value}, date: ${targetDate.value}`
        )
        await savingGoalStore.fetchSavingGoals()
        await notificationStore.fetchNotifications()
        closePopup()
    } catch (error) {
        console.error('Failed to create saving goal: ', error)
    }
}
</script>

<template>
    <PlusButton @click="openPopup" />
    <PopupComponent
        :title="'Create Saving Goal'"
        :show="showPopup"
        @close="closePopup"
    >
        <div>
            <form
                id="createSavingGoalForm"
                @submit.prevent="createGoal"
                class="flex flex-col space-y-2"
            >
                <label class="flex justify-between">
                    Goal Name:
                    <input
                        v-model="name"
                        type="text"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between">
                    Goal Amount:
                    <input
                        v-model="goalAmount"
                        type="number"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between">
                    Goal Date:
                    <input
                        v-model="targetDate"
                        type="date"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <ConfirmButton type="submit">Create</ConfirmButton>
            </form>
        </div>
    </PopupComponent>
</template>

<style scoped></style>

<script setup>
import BadgeIcon from '@/assets/badge.svg'

// Define the achievement prop
const props = defineProps({
    header: String,
    desc: String,
})
</script>

<template>
    <div class="flex flex-row border rounded-xl text-white m-5 p-5">
        <component :is="BadgeIcon" class="w-6 h-6 fill-current" />
        <div class="flex-col">
            <h1 class="text-xl">{{ props.header }}</h1>
            <p>{{ props.desc }}</p>
        </div>
    </div>
</template>

<style scoped></style>

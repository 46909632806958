<script setup>
import Sidebar from '@/components/navigation/Sidebar.vue'
import StatsCard from '@/components/generic/StatsCard.vue'
import { computed, onMounted, reactive, ref } from 'vue'
import ChallengesListComponent from '@/components/challenge/ChallengesListComponent.vue'
import { useTokenStore } from '@/stores/tokenStore.js'
import router from '@/router/index.js'
import { fetchSavingGoals } from '@/utils/SavingGoalService.js'
import { getChallenges } from '@/utils/ChallengeService.js'
import SavingGoalListComponent from '@/components/savinggoal/SavingGoalListComponent.vue'

const state = reactive({
    date: new Date(),
})

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const formattedDate = computed(() => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }
    let date = state.date.toLocaleDateString('nb-NO', options)
    return capitalizeFirstLetter(date)
})

const store = useTokenStore()

const savingGoals = ref([])
const savingChallenges = ref([])

// TODO when dashboard gets refactored, wait for new way to get notifications

const reloadPage = () => {
    console.log('emit for created captured')
    router.push({ name: 'dash' })
}

onMounted(async () => {
    try {
        savingGoals.value = await fetchSavingGoals()
        savingChallenges.value = await getChallenges()
    } catch (e) {
        console.log(e)
    }
})
</script>

<template>
    <header>
        <h1
            id="welcome-header"
            class="text-3xl font-semibold leading-loose text-white"
        >
            {{ store.username }}'s sparing
        </h1>
        <div class="text-gray-200">{{ formattedDate }}</div>
    </header>
    <hr class="border-gray-700" />
    <div
        class="flex sm:space-x-2 space-x-0 w-full sm:flex-row flex-col space-y-3.5 sm:space-y-0"
    >
        <StatsCard class="flex-grow">
            <SavingGoalListComponent />
        </StatsCard>
        <StatsCard class="flex-grow">
            <ChallengesListComponent :group-saving-goal-id="null" :type="''" />
        </StatsCard>
    </div>
</template>

<style scoped></style>

<script setup></script>

<template>
    <div class="gap-6">
        <div class="p-4 rounded-lg bg-midnight">
            <slot></slot>
        </div>
    </div>
</template>

<style scoped></style>

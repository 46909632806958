<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import CreateGroupSavingComponent from '@/components/savinggoal/CreateGroupSavingComponent.vue'
import GroupSavingComponent from '@/components/savinggoal/GroupSavingComponent.vue'
import { useGroupSavingGoalStore } from '@/stores/groupSavingGoalStore.js'
import StatsCard from '@/components/generic/StatsCard.vue'
import { useSavingGoalStore } from '@/stores/savingGoalStore.js'
import ProgressBarComponent from '@/components/generic/ProgressBarComponent.vue'
import CreateChallengeComponent from '@/components/challenge/CreateChallengeComponent.vue'
import ChallengesListComponent from '@/components/challenge/ChallengesListComponent.vue'

const store = useGroupSavingGoalStore()
const savingGoalStore = useSavingGoalStore()

const groupSavingGoals = ref(store.groupSavingGoals)

const handleCreated = async () => {
    groupSavingGoals.value = null
    await store.fetchGroupSavingGoals()
}

onMounted(async () => {
    if (store.groupSavingGoals.length === 0) {
        await store.fetchGroupSavingGoals()
        await savingGoalStore.fetchGroupSavingGoalsByRelation()
        groupSavingGoals.value = store.groupSavingGoals
    }
})

const state = reactive({
    date: new Date(),
})

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const formattedDate = computed(() => {
    const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }
    let date = state.date.toLocaleDateString('nb-NO', options)
    return capitalizeFirstLetter(date)
})
</script>

<template>
    <header>
        <div class="flex justify-between space-x-2">
            <div>
                <h1 class="text-3xl font-semibold leading-loose text-white">
                    Gruppesparemål
                </h1>
                <div class="text-gray-200">{{ formattedDate }}</div>
            </div>
            <CreateChallengeComponent :is-group-challenge="true" />
            <CreateGroupSavingComponent @created="handleCreated" />
        </div>
    </header>
    <hr class="border-gray-700" />

    <div>
        <div
            class="flex space-x-0 w-full sm:flex sm:flex-col sm:gap-4 flex-col space-y-3.5 sm:space-y-0 text-white"
        >
            <div
                v-for="groupSavingGoal in groupSavingGoals"
                :key="groupSavingGoal.groupSavingGoalId"
                class="flex grow"
            >
                <div
                    class="sm:space-x-2 sm:grid sm:grid-cols-2 space-y-2 sm:space-y-0 w-full"
                >
                    <StatsCard class="flex-grow">
                        <GroupSavingComponent
                            :group-saving-goal-id="
                                groupSavingGoal.groupSavingGoalId
                            "
                        >
                        </GroupSavingComponent>
                    </StatsCard>
                    <StatsCard class="flex grow w-full">
                        <ChallengesListComponent
                            :group-saving-goal-id="
                                groupSavingGoal.groupSavingGoalId
                            "
                            :type="''"
                        >
                        </ChallengesListComponent>
                    </StatsCard>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

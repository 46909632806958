// services/SavingGoalService.js
import axios from 'axios'
import { useTokenStore } from '@/stores/tokenStore.js'
import { logOut } from '@/utils/logOut.js'

const api_url = import.meta.env.VITE_API_URL // Ensure you have your base URL set correctly in your environment variables

export const createSavingGoal = async (name, goalAmount, targetDate) => {
    const tokenStore = useTokenStore()
    try {
        console.log(goalAmount)
        const response = await axios.post(
            api_url + '/saving_goals',
            {
                name: name,
                goalAmount: goalAmount,
                deadline: targetDate,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Failed to create saving goal:', e)
    }
}

export const fetchSavingGoals = async () => {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + `/saving_goals`, {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${tokenStore.jwtToken}`, // Ensure the token is being sent correctly
            },
        })
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Failed to fetch saving goals:', e)
    }
}

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useProfileStore } from '@/stores/profileStore.js'
import { setProfilePicture } from '@/utils/UserService.js'

let profilePhoto = ref(null)
const imageUploaded = ref(false)
const profileStore = useProfileStore()
const router = useRouter()
let file = null

const emit = defineEmits('uploaded')

const handleFileUpload = (event) => {
    file = event.target.files[0]
    if (!file) return

    const reader = new FileReader()
    reader.onload = () => {
        profilePhoto.value = reader.result
        profileStore.setPicture(profilePhoto.value)
    }
    reader.readAsDataURL(file)
    imageUploaded.value = true
}

const skipPicture = () => {
    profileStore.clearPicture()
    router.push({ path: '/dash' })
}

const savePicture = async () => {
    const response = await setProfilePicture(file)
    await profileStore.getPicture()
    console.log(response)
    emit('uploaded')
}

let fileInput = ref(null)

onMounted(() => {
    fileInput.value = document.querySelector('#fileInput')
})

const triggerFileInput = () => {
    fileInput.value.click()
}
</script>

<template>
    <div
        class="flex flex-col flex-1 text-center items-center justify-center space-y-2"
    >
        <div
            id="add_picture"
            class="text-white font-medium flex flex-col items-center p-3"
        >
            <a class="m-3">Legg til profilbilde:</a>
            <img :src="profilePhoto" v-if="imageUploaded" alt="Profile-Photo" />
            <input
                type="file"
                id="fileInput"
                @change="handleFileUpload"
                accept="image/png"
                class="hidden"
            />
            <button
                @click="triggerFileInput"
                class="rounded-lg bg-gray-600 p-2"
            >
                Velg fil
            </button>
        </div>
        <div id="continue" class="text-white space-x-2">
            <button @click="savePicture" class="rounded-lg bg-primary p-2">
                Lagre
            </button>
        </div>
    </div>
</template>

<style scoped></style>

import axios from 'axios'
import { useTokenStore } from '@/stores/tokenStore.js'
import { logOut } from '@/utils/logOut.js'

const api_url = import.meta.env.VITE_API_URL

export const createGroupSavingGoal = async (title, emails, savingGoal) => {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.post(
            api_url + '/group-saving-goals',
            {
                title: title,
                emails: emails,
                savingGoalDTO: savingGoal,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log('Error: ', e)
    }
}

export const fetchGroupSavingGoals = async () => {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/group-saving-goals', {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${tokenStore.jwtToken}`,
            },
        })
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log('Error: ', e)
    }
}

export const joinGroupSavingGoal = async (groupSavingGoalId, goalAmount) => {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.put(
            api_url + '/group-saving-goals',
            {
                groupSavingGoalId: groupSavingGoalId,
                goalAmount: goalAmount,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log('Error: ', e)
    }
}

export async function submitGroupChallengeEntry(challengeId) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.post(
            `${api_url}/saving_challenges/${challengeId}/submit-entry`,
            {},
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response?.status === 403) {
            await logOut()
        }
        console.error('Error submitting challenge entry:', e)
        return null
    }
}

export async function depositGroupSavingAmount(savingGoalId, amount) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.post(
            `${api_url}/deposits`,
            {
                savingGoalId: savingGoalId,
                amount: amount,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data // Assuming this returns the updated running total or some confirmation
    } catch (e) {
        if (e.response?.status === 403) {
            await logOut()
        }
        console.error('Error making a deposit:', e)
        return null
    }
}

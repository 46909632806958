<script setup>
import ChallengeComponent from '@/components/challenge/ChallengeComponent.vue'
import { computed, provide, onMounted, ref } from 'vue'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import CreateChallengeComponent from '@/components/challenge/CreateChallengeComponent.vue'
import { useChallengesStore } from '@/stores/challengeStore.js'
import ExpandButton from '@/components/buttons/ExpandButton.vue'
import { useGroupSavingGoalStore } from '@/stores/groupSavingGoalStore.js'
import { useTokenStore } from '@/stores/tokenStore.js'
import { asyncComputed } from '@vueuse/core'

const props = defineProps({
    groupSavingGoalId: {
        type: Number,
        required: true,
        default: -1,
    },
    type: String,
})

const store = useChallengesStore()
const tokenStore = useTokenStore()
const groupSavingStore = useGroupSavingGoalStore()

const savingChallenges = computed(() => {
    return store.challenges
})

const isExpanded = ref(false)
const isDataLoaded = computed(() => !!groupSavingGoal.value)

const toggleExpanded = () => {
    if (isButtonDisabled.value) return
    isExpanded.value = !isExpanded.value
}

const displayChallenges = computed(() => {
    if (props.type === 'GROUP') {
        return isExpanded.value ? groupChallenges.value : groupChallenges.value
    } else {
        return isExpanded.value
            ? savingChallenges.value
            : savingChallenges.value.slice(0, 3)
    }
})

const expose = { isExpanded, toggleExpanded }

onMounted(async () => {
    await store.fetchChallenges()
    await groupSavingStore.fetchGroupSavingGoals()
    savingChallenges.value = store.challenges
})

const groupSavingGoal = computed(() => {
    return groupSavingStore.groupSavingGoals.find(
        (s) => s.groupSavingGoalId === props.groupSavingGoalId
    )
})

const groupSavingGoalRelation = computed(() => {
    if (!groupSavingGoal.value) return []
    const userEmail = tokenStore.email
    return groupSavingGoal.value.groupSavingGoalRelations.filter(
        (relation) => relation.user.email === userEmail
    )
})

const groupChallenges = asyncComputed(async () => {
    const savingGoalIds = groupSavingGoalRelation.value.map(
        (relation) => relation.savingGoal.savingId
    )
    const response = await store.getChallengesForSavingGoal(savingGoalIds)
    return response
})

/*
const groupChallenges = computed(() => {
  return store.fetchChallengesBySavingGoal(savingGoalId)
})
 */

provide('isExpanded', isExpanded)

const isButtonDisabled = computed(() => {
    return savingChallenges.value.length <= 3
})
</script>

<template>
    <div class="p-2 rounded-xl text-white">
        <div class="flex justify-between items-center mb-2">
            <h1 class="text-xl mb-2">Challenges</h1>
            <div class="space-x-2">
                <CreateChallengeComponent />
                <ExpandButton
                    @click="toggleExpanded"
                    :disabled="isButtonDisabled"
                />
            </div>
        </div>
        <div
            v-for="(challenge, index) in displayChallenges"
            :key="challenge.savingChallengeId"
            class="mb-2 last:mb-0"
        >
            <ChallengeComponent
                :saving-challenge-id="challenge.savingChallengeId"
                :saving-goal-id="challenge.savingGoalId"
            >
            </ChallengeComponent>
            <hr v-if="index !== groupChallenges" class="border-gray-700" />
        </div>
    </div>
</template>

<style scoped></style>

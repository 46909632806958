import { useTokenStore } from '@/stores/tokenStore.js'
import { logOut } from '@/utils/logOut.js'
import axios from 'axios'
const api_url = import.meta.env.VITE_API_URL

export async function getQuestions() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/questions', {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${tokenStore.jwtToken}`,
            },
        })

        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

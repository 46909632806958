<script setup>
import SavingGoalComponent from '../components/savinggoal/SavingGoalComponent.vue'
import ChallengesListComponent from '../components/challenge/ChallengesListComponent.vue'
import SavingGoalListComponent from '../components/savinggoal/SavingGoalListComponent.vue'

// Sample data
const title = 'Saving Goal'
const progressPrompt = "You're doing great!"
const progress = 450
const goal = 1000

const challenges = [
    {
        id: 1,
        title: 'Challenge 1',
        progress: 30,
        goal: 100,
    },
    {
        id: 2,
        title: 'Challenge 2',
        progress: 60,
        goal: 200,
    },
    {
        id: 3,
        title: 'Challenge 3',
        progress: 50,
        goal: 300,
    },
]

const savingGoals = [
    {
        title: 'Saving Goal 1',
        progressPrompt: 'Saving Goal prompt 1',
        savingProgress: 5,
        savingGoal: 1000,
    },
    {
        title: 'Saving Goal 2',
        savingProgress: 1000,
        savingGoal: 2000,
    },
    {
        title: 'Saving Goal 3',
        savingProgress: 300,
        savingGoal: 3000,
    },
]
</script>

<template>
    <div class="container mx-auto p-4">
        <SavingGoalComponent
            :name="title"
            :progressPrompt="progressPrompt"
            :runningTotal="progress"
            :goalAmount="goal"
        >
        </SavingGoalComponent>
        <ChallengesListComponent
            :savingChallenges="challenges"
        ></ChallengesListComponent>
        <div class="flex justify-center my-4">
            //Change these with generic button components
            <button class="bg-sky text-white px-4 py-2 rounded-lg">
                Custom deposit
            </button>
            <button class="bg-sky text-white px-4 py-2 rounded-lg ml-4">
                Withdraw from savings
            </button>
        </div>

        <SavingGoalListComponent
            :saving-goals="savingGoals"
        ></SavingGoalListComponent>
    </div>
</template>

<style scoped></style>

import { defineStore } from 'pinia'
import { getAchievements } from '@/utils/UserService.js'

export const useAchievementStore = defineStore('AchievementStore', {
    state: () => {
        return {
            achievements: [],
            achievements_len: 0,
        }
    },

    actions: {
        async fetchAchievements() {
            try {
                this.achievements = await getAchievements()
                this.achievements_len = this.achievements.length
            } catch (err) {
                console.error(err)
            }
        },
    },
    persist: {
        storage: sessionStorage, // note that data in sessionStorage is cleared when the page session ends
    },
})

<script setup>
import { onMounted } from 'vue'
import { useTokenStore } from '@/stores/tokenStore.js'
import router from '@/router/index.js'
import { getEmail, getUsername } from '@/utils/UserService.js'

function getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
}

onMounted(async () => {
    await new Promise(r => setTimeout(r, 2000));
    let store = await useTokenStore()
    let token = getCookie('token')
    await store.saveToken(token)
    await store.saveUsername(await getUsername())
    await store.saveEmail(await getEmail())
    await router.push({ path: '/questions' })
})
</script>

<template>
    <div>
        <h1>Login var vellykket</h1>
    </div>
</template>

<style scoped></style>

import { useTokenStore } from '@/stores/tokenStore.js'
import axios from 'axios'
import { logOut } from '@/utils/logOut.js'
const api_url = import.meta.env.VITE_API_URL

export async function getChallenges() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/saving_challenges', {
            email: tokenStore.email,
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${tokenStore.jwtToken}`,
            },
        })
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

export async function getChallengesBySavingGoal(id) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(
            api_url + '/saving_goals/' + id + '/challenges',
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

//not functional
export async function submitChallengeEntry(id) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.post(
            api_url + '/saving_challenges/' + id + '/submit-entry',
            {},
            {
                email: tokenStore.email,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

export async function createChallenge(
    savingGoalId,
    name,
    icon,
    amount,
    amtDays
) {
    const tokenStore = useTokenStore()
    try {
        console.log(amtDays)
        const response = await axios.post(
            api_url + '/saving_challenges',
            {
                savingGoalId: savingGoalId,
                description: name,
                icon: icon,
                amount: amount,
                amtDays: amtDays,
                daysCompleted: 1,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}
export const getRecommendation = async (savingGoalId) => {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(
            api_url +
                `/saving_challenges/recommendation?savingGoalId=` +
                savingGoalId,
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`, // Ensure the token is being sent correctly
                },
            }
        )
        console.log('generating: ', response)
        return JSON.parse(response.data.choices[0].message.content)
    } catch (e) {
        console.log(e)
        // if (e.response.status === 403) {
        //     await logOut()
        // }
        console.error('Failed to fetch saving goals:', e)
    }
}

import { useTokenStore } from '@/stores/tokenStore.js'
import axios from 'axios'
import { logOut } from '@/utils/logOut.js'
const api_url = import.meta.env.VITE_API_URL

export async function depositSavedAmount(savingGoalId, amount) {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.post(
            api_url + '/deposits',
            {
                savingGoalId: savingGoalId, //needs
                email: tokenStore.email,
                amount: amount,
            },
            {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${tokenStore.jwtToken}`,
                },
            }
        )
        //should return the running total of the user's saved amount
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.log(e)
    }
}

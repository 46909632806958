import { useTokenStore } from '@/stores/tokenStore.js'
import { logOut } from '@/utils/logOut.js'
import axios from 'axios'

const api_url = import.meta.env.VITE_API_URL

export async function getUsername() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/users/self', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + tokenStore.jwtToken,
            },
        })
        console.log(response.data)
        return response.data.name
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Error:', e)
    }
}
export async function getEmail() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/users/self', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + tokenStore.jwtToken,
            },
        })
        console.log(response.data)
        return response.data.email
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Error:', e)
    }
}

export async function getNotifications() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(
            api_url + '/users/self/notifications',
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + tokenStore.jwtToken,
                },
            }
        )
        console.log(response.data)
        return response.data
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Error:', e)
    }
}

export async function setProfilePicture(picture) {
    const tokenStore = useTokenStore()
    const formData = new FormData()
    formData.append('image', picture)
    try {
        const response = await axios.put(
            api_url + '/users/self/picture',
            formData,
            {
                headers: {
                    Authorization: 'Bearer ' + tokenStore.jwtToken,
                },
            }
        )
        return response
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Error:', e)
    }
}

export async function getProfilePicture() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/users/self', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + tokenStore.jwtToken,
            },
            timeout: 5000,
        })
        return response.data.picture
    } catch (e) {
        if (e.code === 'ECONNABORTED') {
            console.error('Request timed out:', e)
            // Handle timeout here
        } else if (e.response.status === 403) {
            await logOut()
        } else {
            console.error(
                'Error encountered while fetching profile picture:',
                e
            )
        }
    }
}

export async function getAchievements() {
    const tokenStore = useTokenStore()
    try {
        const response = await axios.get(api_url + '/users/self', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + tokenStore.jwtToken,
            },
        })
        console.log(response.data)
        return response.data.achievements
    } catch (e) {
        if (e.response.status === 403) {
            await logOut()
        }
        console.error('Error:', e)
    }
}

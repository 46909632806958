import { defineStore } from 'pinia'
import { getProfilePicture } from '@/utils/UserService.js'

export const useProfileStore = defineStore('ProfileStore', {
    state: () => {
        return {
            picture: '',
        }
    },
    actions: {
        setPicture(picture) {
            try {
                this.picture = picture
            } catch (err) {
                console.log(err)
            }
        },
        clearPicture() {
            this.picture = ''
        },
        async getPicture() {
            const data = await getProfilePicture()
            this.picture = data
            return this.picture
        },
    },
    //persist: sessionStorage,
})

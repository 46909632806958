<script setup>
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import { joinGroupSavingGoal } from '@/utils/GroupSavingGoalService.js'
import { ref, onMounted } from 'vue'

const props = defineProps({
    notif: {
        required: true,
        type: {},
    },
})

const emit = defineEmits('submitted')

const amount = ref('')

const acceptInvite = async () => {
    await joinGroupSavingGoal(props.notif.groupSavingGoalId, amount.value)
    emit('submitted')
}

onMounted(() => {
    console.log(props.notif)
})
</script>
<template>
    <div class="flex flex-col">
        <h1>
            Du har blitt invitert til sparemålet "<i>{{ props.notif.title }}</i
            >"
        </h1>
        <h2>
            Av: <i>{{ props.notif.owner }}</i>
        </h2>
        <h1>⠀</h1>
        <p>
            <i>{{ props.notif.owner }}</i> har anbefalt
            <i>{{ props.notif.goalAmount }}kr</i> som din andel.
        </p>
        <p>Du kan endre denne som du vil under:</p>
        <form @submit.prevent="acceptInvite" class="flex flex-col space-y-2">
            <label class="flex justify-between m-5">
                Andel:
                <input
                    v-model="amount"
                    type="text"
                    required
                    class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                />
            </label>
            <ConfirmButton type="submit">Aksepter invitasjon</ConfirmButton>
        </form>
    </div>
</template>

import { defineStore } from 'pinia'
import {
    getChallenges,
    getChallengesBySavingGoal,
    submitChallengeEntry,
} from '@/utils/ChallengeService.js'
import { depositSavedAmount } from '@/utils/DespositService.js'

export const useChallengesStore = defineStore('challenges', {
    state: () => {
        return {
            challenges: [],
            groupChallenges: [],
        }
    },
    actions: {
        async fetchChallenges() {
            try {
                const data = await getChallenges()
                this.challenges = data.map((challenge) => ({
                    ...challenge,
                    savingChallengeId: challenge.savingChallengeId,
                    savingGoalId: challenge.savingGoalId,
                    icon: challenge.icon,
                    amount: challenge.amount,
                    amtDays: challenge.amtDays,
                    daysCompleted: challenge.daysCompleted,
                    description: challenge.description,
                    doneForToday: challenge.doneForToday || false,
                }))
            } catch (error) {
                console.error('Failed to fetch challenges:', error)
                this.challenges = []
            }
        },

        async fetchChallengesBySavingGoal(savingGoalId) {
            const challenges = await getChallengesBySavingGoal(savingGoalId)
            this.challenges = challenges.map((challenge) => ({
                ...challenge,
                savingChallengeId: challenge.savingChallengeId,
            }))
        },

        async getChallengesForSavingGoal(savingGoalId) {
            const data = this.challenges.filter((challenge) =>
                savingGoalId.includes(challenge.savingGoalId)
            )
            return data.map((challenge) => ({
                ...challenge,
                savingChallengeId: challenge.savingChallengeId,
                savingGoalId: challenge.savingGoalId,
                icon: challenge.icon,
                amount: challenge.amount,
                amtDays: challenge.amtDays,
                daysCompleted: challenge.daysCompleted,
                description: challenge.description,
                doneForToday: challenge.doneForToday || false,
            }))
        },

        updateProgress(savingChallengeId, newProgress) {
            const index = this.challenges.findIndex(
                (c) => c.savingChallengeId === savingChallengeId
            )
            if (index !== -1) {
                this.challenges[index].daysCompleted = newProgress
            }
        },
        async submitEntry(challengeId) {
            try {
                const response = await submitChallengeEntry(challengeId)
                if (
                    response &&
                    response.includes('Challenge completed for today')
                ) {
                    const newProgress = parseInt(
                        response.replace(/\D/g, ''),
                        10
                    )
                    this.updateProgress(challengeId, newProgress)
                    return newProgress
                } else {
                    console.error('Challenge already submitted for today')
                    return undefined
                }
            } catch (error) {
                console.error('Submission failed:', error)
                return undefined
            }
        },
        async depositChallengeAmount(savingGoalId, amount) {
            try {
                await depositSavedAmount(savingGoalId, amount) // Ensure this function exists and works
                console.log(
                    `Amount ${amount} deposited to savingGoal ${savingGoalId}`
                )
            } catch (error) {
                console.error('Deposit failed:', error)
            }
        },
    },
})

<script setup>
import { useTokenStore } from '../stores/tokenStore.js'
import SetProfilePictureComponent from '@/components/SetProfilePictureComponent.vue'
import { computed, onMounted, ref } from 'vue'
import { useProfileStore } from '@/stores/profileStore.js'
import defaultPicture from '@/assets/default-picture.png'
import { computedAsync } from '@vueuse/core'

const profileStore = useProfileStore()

const tokenStore = useTokenStore()
const username = tokenStore.username
const email = tokenStore.email
const base64ImageData = computedAsync(async () => {
    return await profileStore.getPicture()
})
const showDialogue = ref(true)

const updatePicture = async () => {
    base64ImageData.value = await profileStore.getPicture()
    showDialogue.value = false
}

onMounted(() => {
    console.log(base64ImageData.value)
})
</script>

<template>
    <div class="flex flex-col items-center justify-center min-h-screen py-2">
        <div
            class="p-6 max-w-sm mx-auto rounded-xl shadow-md flex items-center space-x-4 bg-midnight"
        >
            <div>
                <div class="text-xl font-medium text-white">Profile</div>
                <div class="flex flex-col items-center">
                    <div
                        class="w-32 h-32 m-3 rounded-full overflow-hidden flex items-center justify-center bg-gray-200 text-gray-400 text-sm"
                    >
                        <img
                            :src="
                                base64ImageData
                                    ? 'data:image/png;base64,' + base64ImageData
                                    : defaultPicture
                            "
                            alt="Profile Picture"
                            class="w-full h-full block object-cover"
                        />
                    </div>
                    <p class="text-gray-200">Brukernavn: {{ username }}</p>
                    <p class="text-gray-200">Email: {{ email }}</p>
                </div>
                <hr class="border-gray-700 my-4" />
                <SetProfilePictureComponent
                    v-if="showDialogue"
                    @uploaded="updatePicture"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script setup>
import { inject } from 'vue'
import ArrowDown from '@/assets/down.svg'

const isExpanded = inject('isExpanded', false)

const props = defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})
</script>

<template>
    <button :disabled="props.disabled">
        <ArrowDown
            :class="{ 'transform rotate-180': isExpanded }"
            class="w-6 h-6 fill-primary"
        />
    </button>
</template>

<style scoped></style>

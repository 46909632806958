<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import ProgressBarComponent from '@/components/generic/ProgressBarComponent.vue'
import { useSavingGoalStore } from '@/stores/savingGoalStore.js'
import defaultPicture from '@/assets/default-picture.png'

const props = defineProps({
    savingId: {
        Number,
        required: true,
    },
    email: String,
    type: String,
})

const store = useSavingGoalStore()
const isLoading = ref(true)

onMounted(async () => {
    if (store.savingGoals.length === 0) {
        await store.fetchGroupSavingGoalsByRelation()
        console.log('groupSavingGoals in store', store.groupSavingGoals)
        await store.fetchSavingGoals()
    }
    isLoading.value = false
})

watch(store.savingGoals, (newVal) => {}, { deep: true })

const savingGoal = computed(() => {
    if (props.type === 'GROUP') {
        return store.groupSavingGoals.find((s) => s.savingId === props.savingId)
    } else {
        return store.savingGoals.find((s) => s.savingId === props.savingId)
    }
})

const user = computed(() => {
    if (props.type !== 'GROUP') {
        return null
    }
    return store.user.find((u) => u.email === props.email)
})

const percentage = computed(() => {
    if (savingGoal.value) {
        return (
            (savingGoal.value.runningTotal / savingGoal.value.goalAmount) * 100
        )
    }
    return 0
})
</script>

<template>
    <div v-if="!isLoading && savingGoal">
        <div class="p-2 text-white rounded-lg">
            <div class="flex align-middle space-x-2 items-center">
                <img
                    v-if="props.type === 'GROUP'"
                    :src="
                        user.picture
                            ? 'data:image/png;base64,' + user.picture
                            : defaultPicture
                    "
                    alt="Saving Goal Image"
                    class="w-12 h-12 rounded-full object-cover"
                />
                <div class="flex-grow">
                    <div class="flex-col items-center mb-0.5">
                        <h2
                            v-if="props.type !== 'GROUP'"
                            class="text-xl font-bold"
                        >
                            {{ savingGoal.name }}
                        </h2>
                        <h2
                            v-if="props.type === 'GROUP'"
                            class="text-xl font-bold"
                        >
                            {{ user.name }}
                        </h2>
                        <p
                            v-if="props.type === 'GROUP'"
                            class="text-sm font-italic"
                        >
                            {{ user.email }}
                        </p>
                    </div>
                    <div class="bg-white rounded-full overflow-hidden">
                        <ProgressBarComponent
                            class="bg-sunset h-2 rounded-full"
                            :progress="savingGoal.runningTotal"
                            :goal="savingGoal.goalAmount"
                        >
                        </ProgressBarComponent>
                    </div>
                    <div class="flex items-center justify-between">
                        <span
                            >{{ savingGoal.runningTotal }} /
                            {{ savingGoal.goalAmount }}</span
                        >
                        <div class="">{{ percentage.toFixed(0) }}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <p>{{ isLoading }}</p>
        <p>savinggoal loaded: {{ savingGoal }}</p>
        <p>savingId loaded: {{ props.savingId }}</p>
        <p>no data on given savinggoal</p>
    </div>
</template>

<style scoped></style>

<script setup>
import { onBeforeUnmount, onMounted, ref, computed } from 'vue'
import { RouterView } from 'vue-router'
import router from '@/router/index.js'
import Navbar from './components/navigation/Navbar.vue'
import PopupComponent from './components/generic/PopupComponent.vue'
import { useTokenStore } from '@/stores/tokenStore'
import FooterUrl from '@/assets/footer_image.svg?url'
import LogoUrl from '@/assets/sparesafari_logo.svg?url'
import Sidebar from '@/components/navigation/Sidebar.vue'
import { useSidebarStore } from '@/stores/sidebarStore.js'
import ConfettiComponent from '@/components/achievement/ConfettiComponent.vue'
import { useNotificationStore } from './stores/notificationStore'
import AcceptInviteComponent from './components/AcceptInviteComponent.vue'

const showAchieveNotif = ref(false)
const showInviteNotif = ref(false)

const achievementName = ref('Achievement name')
const achievementDesc = ref('') // Achievement description

const notifObj = ref()

const tokenStore = useTokenStore()
const notificationStore = useNotificationStore()

const notifications = computed(() => {
    return notificationStore.fetchNotifications()
})

const pushNotif = () => {
    const notif = notificationStore.popFirst()

    if (notif == null) return

    console.log('Notif: ', notif)

    switch (notif.type) {
        case 'ACHIEVEMENT':
            achievementName.value = notif.name
            if (notif.description) achievementDesc.value = notif.description
            showAchieveNotif.value = true
            break
        case 'GROUP_INVITATION':
            notifObj.value = JSON.parse(notif.description)
            showInviteNotif.value = true
            break
        default:
            console.error("Notification type didn't match a known enum")
    }
}

router.beforeEach(async () => {
    // Triggered on each route push
    if (!tokenStore.loggedIn) return

    await notificationStore.fetchNotifications()
    console.log('Notifications in store: ', notificationStore.notifications)
})

const sidebarStore = useSidebarStore()

onMounted(() => {
    window.addEventListener('resize', sidebarStore.checkIsMobile)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', sidebarStore.checkIsMobile)
})

const updateNotifs = async () => {
    if (tokenStore.loggedIn) {
        await notificationStore.fetchNotifications()
    }
}

setInterval(updateNotifs, 5000)
</script>

<template>
    <PopupComponent
        :title="'Ny achievement!'"
        :show="showAchieveNotif"
        @close="showAchieveNotif = false"
    >
        <div>
            <ConfettiComponent :showConfetti="showAchieveNotif" />
            <h1
                class="bg-gradient-to-tr font-semibold content-center from-sand to-sky p-3"
            >
                {{ achievementName }}
            </h1>
            <span class="m-2 hyphens-auto" lang="no">{{
                achievementDesc
            }}</span>
        </div>
    </PopupComponent>
    <PopupComponent
        :title="'Du er blitt invitert til et sparemål!'"
        :show="showInviteNotif"
        @close="showInviteNotif = false"
    >
        <div>
            <AcceptInviteComponent
                :notif="notifObj"
                @submitted="showInviteNotif = false"
            />
        </div>
    </PopupComponent>
    <Navbar @displayFirstNotif="pushNotif()" />
    <div class="flex w-full min-h-screen bg-midnightSky">
        <Sidebar v-if="tokenStore.loggedIn" />
        <main
            :class="
                router.currentRoute.value.meta.isLanding
                    ? ''
                    : 'flex flex-col flex-1 gap-6 p-4'
            "
        >
            <RouterView />
        </main>
    </div>
    <footer
        class="bg-terrain relative flex flex-col flex-1 items-center justify-center overflow-hidden pt-2"
    >
        <div
            class="flex items-center justify-center text-center text-midnight absolute p-5 z-10"
        >
            <div>
                <img
                    class="nav-element logo max-h-20 max-w-20"
                    :src="LogoUrl"
                    alt="logo"
                />
            </div>
            <div class="font-Nunito">
                <h2>Spare Safari</h2>
                <h2>NTNU Trondheim</h2>
                <h2>
                    Kontakt:
                    <a
                        class="text-lime-600"
                        href="mailto:kontakt@sparesafari.no"
                        >kontakt@sparesafari.no</a
                    >
                </h2>
            </div>
        </div>
        <div class="p-10 m-7 z-0">
            <img
                class="absolute bottom-0 left-0 w-full h-auto opacity-10"
                :src="FooterUrl"
                alt="Footer Image"
            />
        </div>
        <!-- Adjusting image class for full width and automatic height to preserve aspect ratio -->
    </footer>
</template>

<style scoped></style>

<script setup>
import { onBeforeMount, onMounted, ref } from 'vue'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import router from '@/router/index.js'
import { useTokenStore } from '@/stores/tokenStore'

const email = ref('')
const api_url = import.meta.env.VITE_API_URL
const link = ref('')

const tokenStore = useTokenStore()

const subscribeNewsletter = async () => {
    console.log('Subscribing with: ', email.value)
    //LOGIC
}

const routeLogin = async () => {
    await router.push({ path: '/login' })
}

const routeRegister = async () => {
    await router.push({ path: '/signup' })
}

onBeforeMount(async () => {
    if (tokenStore.loggedIn) {
        await router.push({ name: 'newDash' })
    }
})

onMounted(() => {
    link.value = api_url + '/authentication/login/google'
})
</script>

<template>
    <div class="bg-sky">
        <!-- Hero -->
        <section class="relative md:justify-center md:items-center">
            <div
                class="container flex flex-col-reverse lg:flex-row items-center gap-12 pt-14 lg:mb-20 lg:pt-28"
            >
                <!-- Content -->
                <div
                    class="flex flex-1 flex-col items-center px-10 lg:items-start"
                >
                    <h2
                        class="text-midnight text-3xl md:text-4 md:justify-center lg:text-5xl text-center lg:text-left mb-6 md:text-center"
                    >
                        Velkommen til SpareSafari!
                    </h2>
                    <p
                        class="text-midnightSky text-lg text-center lg:text-left mb-6"
                    >
                        En morsom og gratis måte å spare penger sammen med
                        venner, partnere og familie. Kom igang med sparingen og
                        nå dine sparemål!
                    </p>
                    <div class="flex justify-center flex-wrap gap-6 mb-6">
                        <button
                            id="landing-login"
                            type="button"
                            class="active:scale-95 bg-gradient-to-tr content-center bg-midnightSky rounded-xl font-medium px-6 py-2 text-sand shadow-md"
                            @click="routeLogin"
                        >
                            Logg inn
                        </button>
                        <button
                            type="button"
                            class="active:scale-95 bg-gradient-to-tr content-center bg-midnightSky rounded-xl font-medium px-6 py-2 text-sand shadow-md"
                            @click="routeRegister"
                        >
                            Registrer
                        </button>
                        <a
                            :href="link"
                            class="px-4 py-2 border flex gap-2 border-slate-200 dark:border-slate-700 rounded-lg text-sand hover:shadow transition duration-150 bg-midnightSky"
                        >
                            <img
                                class="w-6 h-6"
                                src="https://www.svgrepo.com/show/475656/google-color.svg"
                                loading="lazy"
                                alt="google logo"
                            />
                            <span>Forsett med Google</span>
                        </a>
                    </div>
                </div>
                <!--Image -->
                <div
                    class="flex justify-center flex-1 sm:mb-2 md:mb-16 lg:mb-0 z-10"
                >
                    <img
                        class="w-5/6 h-5/6 rounded-lg shadow-xl sm:w-3/4 sm:h-3/4 md: lg:scale-125"
                        src="@/assets/header.png"
                        alt="preview of website"
                    />
                </div>
            </div>
            <div
                class="hidden md:block overflow-hidden bg-midnightSky rounded-l-full absolute h-80 w-2/4 top-32 right-0 z-0 lg: -bottom-48 lg:-right-0"
            />
        </section>

        <!-- transporting image -->
        <div class="relative bottom-0 w-full">
            <img class="w-full" src="@/assets/landingbg2.png" alt="" />
        </div>

        <!-- Features -->
        <section class="bg-midnight py-20">
            <!-- Heading -->
            <div class="sm:w-3/4 lg:w-5/12 mx-auto px-2">
                <h1 class="text-3xl text-center text-lightBrown">Features</h1>
                <p class="text-center text-sky mt-4">
                    Målet vårt er å gjøre det motiverende og enkelt å spare
                    penger til dine egne formål. Opplev fremgang i sparingen ved
                    å utfordre deg selv til å spare på noe fra hverdagen, og
                    oppnå medaljer og motivasjon underveis. Del opplevelsen
                    sammen med venner og motiver hverandre til suksess!
                </p>
            </div>
            <!-- Feature #1 -->
            <div class="relative mt-20 lg:mt-24">
                <div
                    class="container flex flex-col lg:flex-row items-center justify-center gap-x-24 px-2"
                >
                    <!-- Image -->
                    <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
                        <img
                            class="w-5/6 h-5/6 rounded-lg lg:scale-75 sm:w-3/4 sm:h-3/4 md:w-3/4 md:h-3/4"
                            src="@/assets/feature1.webp"
                            alt="coyote happy to have saved money"
                        />
                    </div>
                    <!-- Content -->
                    <div
                        class="flex flex-1 flex-col items-center lg:items-start"
                    >
                        <h1 class="text-3xl text-lightBrown text-center">
                            Utfordre deg selv til å spare!
                        </h1>
                        <p
                            class="text-sky my-4 text-center lg:text-left sm:w-3/4 lg:w-full"
                        >
                            Lag utfordringer på felt der du selv mener du har
                            noe å spare på, og se fremgangen bygge seg opp helt
                            fram til målet.
                        </p>
                        <ConfirmButton type="button" class="text-xl">
                            Se mer
                        </ConfirmButton>
                    </div>
                </div>
                <div
                    class="hidden lg:block overflow-hidden bg-midnightSky rounded-r-full absolute h-80 w-2/4 -bottom-24 -left-36"
                ></div>
            </div>
            <!-- Feature #2 -->
            <div class="relative mt-20 lg:mt-24">
                <div
                    class="container flex flex-col lg:flex-row-reverse items-center justify-center gap-x-24 px-2 lg:mx-40"
                >
                    <!-- Image -->
                    <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
                        <img
                            class="w-5/6 h-5/6 rounded-lg sm:w-3/4 sm:h-3/4 md:w-3/4 md:h-3/4 lg:scale-75"
                            src="@/assets/feature2.webp"
                            alt="two zebras sitting at a table with a lot of money they saved together"
                        />
                    </div>
                    <!-- Content -->
                    <div
                        class="flex flex-1 flex-col z-10 items-center lg:items-start"
                    >
                        <h1 class="text-3xl text-lightBrown text-center">
                            Spar sammen med dine venner
                        </h1>
                        <p
                            class="text-sky my-4 text-center lg:text-left sm:w-3/4 lg:w-full"
                        >
                            Spar sammen med venner mot et felles mål, se
                            fremgangen og motiver hverandre til å spare mer!
                        </p>
                        <ConfirmButton type="button" class="text-xl">
                            Se mer
                        </ConfirmButton>
                    </div>
                </div>
                <div
                    class="hidden lg:block overflow-hidden bg-midnightSky rounded-l-full absolute h-80 w-2/4 -bottom-24 -right-0"
                ></div>
            </div>
            <!-- Feature #3 -->
            <div class="relative mt-20 lg:mt-52">
                <div
                    class="container flex flex-col lg:flex-row items-center justify-center gap-x-24 px-2"
                >
                    <!-- Image -->
                    <div class="flex flex-1 justify-center z-10 mb-10 lg:mb-0">
                        <img
                            class="w-5/6 h-5/6 rounded-lg sm:w-3/4 sm:h-3/4 md:w-3/4 md:h-3/4 lg:scale-75"
                            src="@/assets/feature3.png"
                            alt="giraffe"
                        />
                    </div>
                    <!-- Content -->
                    <div
                        class="flex flex-1 flex-col items-center lg:items-start"
                    >
                        <h1 class="text-3xl text-lightBrown text-center">
                            Gameified sparing, "instant gratification"
                        </h1>
                        <p
                            class="text-sky my-4 text-center lg:text-left sm:w-3/4 lg:w-full"
                        >
                            Opplev fremgang i sparingen både ved små og store
                            steg på din reise gjennom sparesafarien ved et
                            gameified opplegg som tilbyr måloppnåelser og
                            motiverende tilbakemelinger!
                        </p>
                        <ConfirmButton type="button" class="text-xl">
                            Se mer
                        </ConfirmButton>
                    </div>
                </div>
                <div
                    class="hidden lg:block overflow-hidden bg-midnightSky rounded-r-full absolute h-80 w-2/4 -bottom-24 -left-36"
                ></div>
            </div>
        </section>
    </div>
    <!-- FAQ -->
    <section class="bg-sky py-20 flex flex-col flex-1 items-center">
        <div class="container flex flex-col items-center md:w-3/4">
            <!-- Heading -->
            <div class="sm:w-3/4 lg:w-5/12 mx-auto">
                <h1 class="text-3xl text-center text-midnight">
                    Ofte stilte spørsmål
                </h1>
                <p class="text-center text-midnightSky mt-4">
                    Få svar her i FAQ. Om du har andre spørsmål anngående
                    nettsiden vennligst kontakt oss på epost nedenfor.
                </p>
            </div>
            <!-- FAQ Items -->
            <div
                class="flex flex-col justify-center sm:w-3/4 lg:w-5/12 mt-12 mx-auto px-2"
            >
                <div class="flex items-center justify-center border-b py-4">
                    <span class="flex-1">Er nettsiden gratis å bruke?</span>
                    <i class="text-midnightSky fas fa-chevron-down"
                        >Nei, pay to win</i
                    >
                </div>
                <div class="flex items-center justify-center border-b py-4">
                    <span class="flex-1"
                        >Hvilken gruppe er best i årets systemutvikling 2?</span
                    >
                    <i class="text-midnightSky fas fa-chevron-down"
                        >Gruppe 12 <3</i
                    >
                </div>
                <div class="flex items-center justify-center border-b py-4">
                    <span class="flex-1"
                        >Hvordan kommer jeg igang med sparingen?</span
                    >
                    <i class="text-midnightSky fas fa-chevron-down"
                        >Registrer bruker nå!</i
                    >
                </div>
                <div class="flex items-center justify-center border-b py-4">
                    <span class="flex-1">Hvorfor?</span>
                    <i class="text-midnightSky fas fa-chevron-down"
                        >det er eazy bro</i
                    >
                </div>
            </div>
        </div>
    </section>

    <!-- Contact Us -->
    <section
        class="bg-midnight text-white py-20 flex items-center justify-center"
    >
        <div class="container flex items-center">
            <div class="sm:w-3/4 lg:w-2/4 mx-auto px-2">
                <p class="font-light uppercase text-center mb-8">
                    0+ REGISTRERTE BRUKERE
                </p>
                <h1 class="text-3xl text-center">
                    Bli oppdatert på nettsidens utvikling!
                </h1>
                <div class="flex flex-col sm:flex-row gap-6 m-8">
                    <input
                        type="email"
                        v-model="email"
                        placeholder="Enter your email address"
                        class="focus:outline-none flex-1 px-2 py-3 rounded-md text-black"
                        required
                    />
                    <ConfirmButton
                        type="submit"
                        class="text-xl mx-4"
                        @click="subscribeNewsletter"
                    >
                        Kontakt oss
                    </ConfirmButton>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped></style>

<script setup>
import { useAchievementStore } from '@/stores/achievementStore.js'
import { computed, onMounted } from 'vue'
import AchievementComponent from '@/components/achievement/AchievementComponent.vue'

const achievementStore = useAchievementStore()

const achievements = computed(() => {
    return achievementStore.achievements
})

onMounted(() => {
    achievementStore.fetchAchievements()
    console.log(achievements.value)
})
</script>
<template>
    <div>
        <h1 class="text-white text-center" v-if="achievements.length === 0">
            Du har ingen achievements enda :(
        </h1>
        <ul>
            <li
                v-for="achievement in achievements"
                :key="achievement.achievementId"
            >
                <AchievementComponent
                    :header="achievement.achievementName"
                    :desc="achievement.achievementDescription"
                />
            </li>
        </ul>
    </div>
</template>

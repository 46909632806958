<template>
    <div
        class="grid place-items-center text-4xl text-center text-sky w-full overflow-auto my-6"
    >
        <h1>404</h1>
        <h2>Siden du leter etter finnes ikke :(</h2>
        <h3 class="text-gray text-sm my-5">
            Laster hjemmesiden om 10 sekunder
        </h3>
        <img
            src="@/assets/404-seal.jpg"
            alt="404 page not found. Picture of crying seal lost in savannah."
        />
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    mounted() {
        setTimeout(() => {
            this.$router.push('/')
        }, 10000) // 10000ms = 10s
    },
}
</script>

<script setup>
import { computed, provide, onMounted, ref } from 'vue'

import CreateSavingGoalComponent from '@/components/savinggoal/CreateSavingGoalComponent.vue'
import SavingGoalComponent from '@/components/savinggoal/SavingGoalComponent.vue'

import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import ExpandButton from '@/components/buttons/ExpandButton.vue'

import { useSavingGoalStore } from '@/stores/savingGoalStore.js'

const store = useSavingGoalStore()

let isExpanded = ref(false)

let toggleExpanded = () => {
    console.log('toggleExpanded called, isExpanded:', isExpanded.value)
    isExpanded.value = !isExpanded.value
}

const expose = { isExpanded, toggleExpanded }
provide('isExpanded', isExpanded)

const savingGoals = computed(() => {
    return store.savingGoals
})

const displaySavingGoals = computed(() => {
    return isExpanded.value ? savingGoals.value : savingGoals.value.slice(0, 3)
})

onMounted(async () => {
    await store.fetchSavingGoals()
})
</script>

<template>
    <div class="">
        <div class="flex justify-between items-center mt-2">
            <h2 class="text-xl text-white mb-2">Sparemål</h2>
            <div class="space-x-2">
                <CreateSavingGoalComponent @created="forwardSuccessfulUpdate" />
                <ExpandButton
                    @click="toggleExpanded"
                    :disabled="savingGoals.length <= 3"
                >
                </ExpandButton>
            </div>
        </div>
        <div
            v-for="(savingGoal, index) in displaySavingGoals"
            :key="savingGoal.savingId"
            class="mb-2 last:mb-0"
        >
            <SavingGoalComponent :saving-id="savingGoal.savingId">
            </SavingGoalComponent>
            <hr
                v-if="index !== displaySavingGoals.length - 1"
                class="border-gray-700"
            />
        </div>
    </div>
</template>

<style scoped></style>

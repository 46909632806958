import { defineStore } from 'pinia'

export const useTokenStore = defineStore('TokenStore', {
    state: () => {
        return {
            jwtToken: '',
            email: '',
            username: '',
            loggedIn: false,
            isAdmin: false,
        }
    },

    actions: {
        saveToken(jwtToken) {
            try {
                this.jwtToken = jwtToken
                this.loggedIn = true
            } catch (err) {
                console.log(err)
            }
        },
        saveIsAdmin(bool) {
            try {
                this.isAdmin = bool
            } catch (err) {
                console.log(err)
            }
        },
        saveEmail(email) {
            try {
                this.email = email
            } catch (err) {
                console.log(err)
            }
        },
        saveUsername(username) {
            try {
                this.username = username
            } catch (err) {
                console.log(err)
            }
        },
        logOut() {
            try {
                this.jwtToken = ''
                this.email = ''
                this.username = ''
                this.loggedIn = false
                this.isAdmin = false
            } catch (err) {
                console.log(err)
            }
        },
    },
    persist: {
        storage: sessionStorage, // note that data in sessionStorage is cleared when the page session ends
    },
})

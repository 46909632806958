<script setup>
import ProgressBarComponent from '@/components/generic/ProgressBarComponent.vue'
import ChallengeButtonComponent from '@/components/challenge/ChallengeButtonComponent.vue'
import { computed, onMounted, ref, watchEffect } from 'vue'
import { useChallengesStore } from '@/stores/challengeStore.js'

const props = defineProps({
    savingChallengeId: Number,
    savingGoalId: Number,
})

const store = useChallengesStore()

const challenge = computed(() => {
    if (props.savingChallengeId) {
        const instance = store.challenges.find(
            (c) => c.savingChallengeId === props.savingChallengeId
        )
        if (!instance) {
            return null
        }
        return instance
    }
})

if (!challenge.value) {
    console.log(
        'Challenge data is not available or not loaded yet, ',
        challenge.value
    )
}

watchEffect(() => {})

onMounted(async () => {
    await store.fetchChallenges()
})
</script>

<template>
    <div v-if="challenge" class="flex flex-row p-2">
        <div class="flex flex-col flex-grow text-white">
            <div class="items-center">
                <h3
                    class="font-semibold hyphens-auto w-full max-w-32"
                    lang="no"
                >
                    {{ challenge.description }}
                </h3>
            </div>
            <div class="flex flex-col">
                <div class="bg-white rounded-full overflow-hidden">
                    <ProgressBarComponent
                        :progress="challenge.daysCompleted"
                        :goal="challenge.amtDays"
                    />
                </div>
                <span
                    >{{ challenge.daysCompleted }} /
                    {{ challenge.amtDays }}</span
                >
            </div>
        </div>
        <ChallengeButtonComponent
            :emoji="challenge.icon"
            :amount="challenge.amount"
            :saving-challenge-id="challenge.savingChallengeId"
            :saving-goal-id="challenge.savingGoalId"
            :done-for-today="challenge.doneForToday"
            class="ml-1 text-black"
        >
        </ChallengeButtonComponent>
    </div>
    <div v-else>no data loaded</div>
</template>

<style scoped></style>

<script setup>
import { ref, computed } from 'vue'

import NavbarButton from '@/components/navigation/NavbarButton.vue'
import DropDownButton from '@/components/navigation/DropDownButton.vue'
import router from '@/router/index.js'
import { useTokenStore } from '@/stores/tokenStore.js'
import { logOut } from '@/utils/logOut.js'
import LogoUrl from '@/assets/sparesafari_logo.svg?url'
import { useSidebarStore } from '@/stores/sidebarStore.js'
import { useRoute } from 'vue-router'
import { useNotificationStore } from '../../stores/notificationStore'

const emit = defineEmits('displayFirstNotif')

const tokenStore = useTokenStore()

const showDropdown = ref(false)

const route = useRoute()

const toggleDropdown = () => {
    showDropdown.value = !showDropdown.value
}

const hideDropdown = () => {
    setTimeout(() => {
        showDropdown.value = false
    }, 100)
}

const logo_click = () => {
    if (tokenStore.loggedIn) {
        router.push({ name: 'newDash' })
        return
    }
    router.push({ name: 'home' })
}

function handleNavClick() {
    showDropdown.value = false
}

function handleLogout() {
    showDropdown.value = false
    logOut()
}

const sidebarStore = useSidebarStore()
const notificationStore = useNotificationStore()
const notifications_len = computed(() => {
    return notificationStore.notifications_len
})

const toggleSidebar = () => {
    sidebarStore.toggleSidebar()
    console.log('sidebarStore.sidebarOpen:', sidebarStore.isSidebarOpen)
}
</script>

<template>
    <div class="h-screen-20 flex items-center justify-center relative">
        <nav
            class="flex justify-between items-center px-2 max-h-20 w-full bg-lightBrown"
        >
            <div class="nav-logo flex items-center">
                <img
                    @click="logo_click"
                    class="nav-element logo max-h-20 max-w-20"
                    :src="LogoUrl"
                    alt="logo"
                />
                <b class="hidden sm:block mx-1">SpareSafari</b>
            </div>
            <div class="flex flex-row">
                <div>
                    <span>{{ notifications_len }}</span
                    ><NavbarButton
                        @click="emit('displayFirstNotif')"
                        :class="{ 'animate-ping': notifications_len > 0 }"
                        >🔔</NavbarButton
                    >
                </div>
                <NavbarButton
                    id="dropdown"
                    @click="toggleSidebar"
                    @blur="hideDropdown"
                    class="sm:hidden max-h-[80%] flex-grow flex justify-center items-center p-2.5"
                    >☰</NavbarButton
                >
            </div>
        </nav>
        <div
            class="dropdown absolute top-full w-full z-50"
            v-show="showDropdown"
        >
            <DropDownButton
                data-test-id="landingDropdown"
                :to="{ name: 'landing' }"
                @click="handleNavClick"
                >Landing</DropDownButton
            >
            <DropDownButton
                data-test-id="dashboardDropdown"
                :to="{ name: 'dash' }"
                v-if="tokenStore.loggedIn"
                @click="handleNavClick"
                >Dashboard</DropDownButton
            >
            <DropDownButton
                data-test-id="loginDropdown"
                :to="{ name: 'login' }"
                @click="handleNavClick"
                >Logg inn</DropDownButton
            >
            <DropDownButton
                data-test-id="signupDropdown"
                :to="{ name: 'signup' }"
                v-if="!tokenStore.loggedIn"
                @click="handleNavClick"
                >Registrer</DropDownButton
            >
            <DropDownButton
                data-test-id="logoutDropdown"
                :to="{ name: 'login' }"
                v-if="tokenStore.loggedIn"
                @click="handleLogout"
                >Logg ut</DropDownButton
            >
        </div>
    </div>
</template>

<style scoped>
.nav-element {
    max-height: 80%; /* Adjust as needed */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.logo {
    will-change: filter;
    transition: filter 300ms;
}

.logo:hover {
    filter: drop-shadow(0 0 2em rgba(190, 64, 25, 0.67));
}
</style>

<script setup>
import { computed } from 'vue'

const props = defineProps({
    progress: {
        Number,
        required: true,
    },
    goal: {
        Number,
        required: true,
    },
})

const percentage = computed(() => {
    if (props.goal === 0) return 0
    return (props.progress / props.goal) * 100
})
</script>

<template>
    <div
        class="progress-bar bg-gradient-to-r from-grass to-lightGrass h-2 rounded-full"
        :style="{ width: percentage + '%' }"
    ></div>
</template>

<style scoped></style>

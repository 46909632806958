import { createRouter, createWebHistory } from 'vue-router'

import LandingView from '../views/LandingView.vue'
import SettingsView from '../views/SettingsView.vue'
import SavingGoalsView from '../views/SavingGoalsView.vue'
import SignUpView from '../views/SignUpView.vue'
import LoginView from '../views/LoginView.vue'
import SetProfilePictureComponent from '@/components/SetProfilePictureComponent.vue'
import QuestionsView from '@/views/QuestionsView.vue'
import GroupSavingView from '../views/GroupSavingView.vue'
import NewDashboard from '@/views/NewDashboard.vue'
import AchievementView from '@/views/AchievementView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import ProfileView from '@/views/ProfileView.vue'
import RedirectView from '@/views/RedirectView.vue'
import RedirectSignupView from '@/views/RedirectSignupView.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'home',
            component: LandingView,
            meta: { isLanding: true },
        },
        {
            path: '/landing',
            name: 'landing',
            component: LandingView,
            meta: { isLanding: true },
        },
        {
            path: '/settings',
            name: 'settings',
            component: SettingsView,
        },
        {
            path: '/dash',
            name: 'dash',
            component: NewDashboard,
        },
        {
            path: '/saving-goal',
            name: 'savingGoal',
            component: SavingGoalsView,
        },
        {
            path: '/signup',
            name: 'signup',
            component: SignUpView,
        },
        {
            path: '/login',
            name: 'login',
            component: LoginView,
        },
        {
            path: '/set-profile-picture',
            name: 'setProfilePicture',
            component: SetProfilePictureComponent,
        },
        {
            path: '/questions',
            name: 'questions',
            component: QuestionsView,
        },
        {
            path: '/group-saving-goal',
            name: 'groupSavingGoal',
            component: GroupSavingView,
        },
        {
            path: '/new-dash',
            name: 'newDash',
            component: NewDashboard,
        },
        {
            path: '/achievements',
            name: 'achievements',
            component: AchievementView,
        },
        {
            path: '/profile',
            name: 'profile',
            component: ProfileView,
        },
        {
            path: '/:pathMatch(.*)*',
            component: NotFoundView,
        },
        {
            path: '/redirect',
            name: 'redirect',
            component: RedirectView,
        },
        {
            path: '/redirect-signup',
            name: 'redirectsignup',
            component: RedirectSignupView,
        },
    ],
})

export default router

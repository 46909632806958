<script setup>
import { ref, onMounted } from 'vue'
import { useTokenStore } from '@/stores/tokenStore.js'
import { useSidebarStore } from '@/stores/sidebarStore.js'
import { logIn } from '@/utils/AuthenticationService.js'
import router from '@/router/index.js'
import { useRoute } from 'vue-router'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import { getUsername } from '@/utils/UserService.js'
import FooterUrl from '@/assets/footer_image.svg?url'

const userEmail = ref('')
const password = ref('')
const errorMessageVisible = ref(false)

const route = useRoute()

const store = useTokenStore()

async function login() {
    let response = await logIn(userEmail.value, password.value)
    if (response) {
        store.saveToken(response.data)
        store.saveEmail(userEmail.value)

        response = await getUsername()
        console.log(response)
        if (response !== null) {
            store.saveUsername(response)
        } else {
            console.log('Fetching username failed')
        }
        await router.push({ path: '/new-dash' })
    } else {
        errorMessageVisible.value = true
    }
}
console.log(store.loggedIn)

const routeRegister = async () => {
    await router.push({ path: '/signup' })
}
</script>

<template>
    <div class="flex flex-col items-center z-10">
        <h1 class="login-text">Velkommen!</h1>
        <p v-if="route.query.logout" class="text-sky m-5">Du ble logget ut</p>
        <p v-if="errorMessageVisible" id="loginFail" class="text-sky m-5">
            Feil brukernavn/passord
        </p>
        <form action="" method="get" id="form" @submit.prevent="login()">
            <div>
                <label for="email" class="text-sky">Mail</label>
                <input
                    type="email"
                    name="email"
                    id="email"
                    class="input-component"
                    required
                    v-model="userEmail"
                />
            </div>
            <div>
                <label for="password" class="text-sky">Passord</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    class="input-component"
                    required
                    v-model="password"
                />
            </div>
            <input
                class="w-full my-3 active:scale-95 bg-gradient-to-tr content-center from-sand to-sky rounded-xl font-medium px-6 py-2 text-midnight shadow-md"
                type="submit"
                value="Logg inn"
            />
        </form>
    </div>
    <div class="p-10 m-7 z-0">
        <img
            class="absolute bottom-0 left-0 w-full h-auto opacity-10"
            :src="FooterUrl"
            alt="Footer Image"
        />
    </div>
</template>

<style scoped>
.input-component {
    display: block;
    border-radius: 0.5em;
    border: black solid 0.1em;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
}

.login-text {
    --bg-size: 400%;
    --color-one: hsl(78, 48%, 62%);
    --color-two: hsl(78, 33%, 41%);
    font-size: clamp(1rem, 11vw, 10rem);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
        sans-serif;
    background: linear-gradient(
            90deg,
            var(--color-one),
            var(--color-two),
            var(--color-one)
        )
        0 0 / var(--bg-size) 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: move-bg 8s infinite linear;
}

@media (prefers-reduced-motion: no-preference) {
    .login-text {
        animation: move-bg 8s linear infinite;
    }
    @keyframes move-bg {
        to {
            background-position: var(--bg-size) 0;
        }
    }
}
</style>

<script setup>
import { onMounted, ref } from 'vue'
import { getQuestions } from '@/utils/QuestionService.js'
import {
    createAnswers,
    getAnswers,
    updateAnswers,
} from '@/utils/AnswerService.js'
import router from '@/router/index.js'

const questions = ref([])
const isUpdate = ref(false)

onMounted(async () => {
    questions.value = []
    console.log(questions.value)
    let answerResponse = await getAnswers()
    if (answerResponse.totalElements === 0) {
        const response = await getQuestions()
        for (let i = 0; i < response.length; i++) {
            questions.value.push({
                questionId: response[i].questionId,
                question: response[i].question,
                answer: '',
            })
        }
    } else {
        console.log(answerResponse)
        isUpdate.value = true
        for (let i = 0; i < answerResponse.content.length; i++) {
            questions.value.push({
                questionId: answerResponse.content[i].question.questionId,
                question: answerResponse.content[i].question.question,
                answer: answerResponse.content[i].answer,
            })
        }
    }
})
async function sendQuestion() {
    const answers = {}
    for (let i = 0; i < questions.value.length; i++) {
        answers[questions.value[i].questionId] = questions.value[i].answer
    }
    if (isUpdate.value) {
        await updateAnswers(answers)
    } else {
        await createAnswers(answers)
    }
    await router.push({ path: '/profile' })
}
</script>

<template>
    <div class="py-8 px-4 text-white">
        <h2 class="text-2xl font-semibold mb-4">Personlig tilpassing</h2>
        <p class="mb-6">
            Svarene dine her vil bli brukt til å lage personaliserte
            utfordringer med hjelp av KI.
        </p>
        <form @submit.prevent="sendQuestion()">
            <div>
                <h3 class="text-lg font-semibold mb-2">Spørsmål:</h3>
                <div
                    v-for="question in questions"
                    :key="question.id"
                    class="mb-4"
                >
                    <p class="mb-2">{{ question.question }}</p>
                    <textarea
                        v-model="question.answer"
                        class="border rounded-md p-2 w-full text-midnight"
                        required
                    ></textarea>
                </div>
            </div>
            <button
                type="submit"
                class="bg-gray-400 hover:bg-gray-500 active:bg-gray-600 text-white font-semibold py-2 px-4 rounded"
            >
                Lagre svar
            </button>
        </form>
    </div>
</template>

<style scoped></style>

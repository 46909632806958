<script setup>
import PopupComponent from '@/components/generic/PopupComponent.vue'
import ConfirmButton from '@/components/buttons/ConfirmButton.vue'
import { createGroupSavingGoal } from '@/utils/GroupSavingGoalService.js'
import { ref } from 'vue'

const emit = defineEmits(['created'])

const tempEmail = ref()
const showPopup = ref(false)

const openPopup = () => {
    showPopup.value = true
}

const closePopup = () => {
    showPopup.value = false
}

const goalAmount = ref('')
const targetDate = ref('')

const title = ref('')
const emails = ref([])
const savingGoal = ref()

const createGroupSaving = async () => {
    try {
        savingGoal.value = {
            name: title.value,
            goalAmount: goalAmount.value,
            targetDate: targetDate.value,
        }
        console.log(savingGoal.value)
        await createGroupSavingGoal(title.value, emails.value, savingGoal.value)
        console.log(
            `Creating goal with title: ${title.value}, emails: ${emails.value}, saving goal: ${savingGoal.value}`
        )
        emit('created')
        closePopup()
    } catch (error) {
        console.error('Failed to create group saving goal: ', error)
    }
}

const addEmail = () => {
    if (tempEmail.value && validateEmail(tempEmail.value)) {
        emails.value.push(tempEmail.value)
        tempEmail.value = ''
        console.log(emails.value)
    } else {
        alert('enter a valid email')
    }
}

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
}
</script>

<template>
    <button
        class="bg-primary p-2 rounded-lg hover:opacity-70"
        @click="openPopup"
    >
        Lag gruppesparemål
    </button>
    <PopupComponent
        :title="'Lag gruppesparemål'"
        :show="showPopup"
        @close="closePopup"
    >
        <div>
            <form
                @submit.prevent="createGroupSaving"
                class="flex flex-col space-y-2"
            >
                <label class="flex justify-between">
                    Navn:
                    <input
                        v-model="title"
                        type="text"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between">
                    Beløp:
                    <input
                        v-model="goalAmount"
                        type="number"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between">
                    Frist:
                    <input
                        v-model="targetDate"
                        type="date"
                        required
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <label class="flex justify-between">
                    Email:
                    <input
                        v-model="tempEmail"
                        type="email"
                        class="w-full p-2 m-2 border-2 border-blue-500 rounded bg-gray-200"
                    />
                </label>
                <button @click="addEmail">Legg til email</button>
                <ConfirmButton type="submit">Lag</ConfirmButton>
            </form>
        </div>
    </PopupComponent>
</template>

<style scoped></style>

<script setup>
import { RouterLink } from 'vue-router'

const props = defineProps({
    to: {
        type: String,
        required: false,
    },
})
</script>

<template>
    <div class="w-full">
        <RouterLink class="nav-button" :to="{ name: props.to }">
            <slot></slot>
        </RouterLink>
    </div>
</template>

<style scoped></style>

import { defineStore } from 'pinia'
import {
    fetchGroupSavingGoals,
} from '@/utils/GroupSavingGoalService.js'

export const useGroupSavingGoalStore = defineStore('groupSavingGoals', {
    state: () => ({
        groupSavingGoals: [],
    }),
    getters: {},
    actions: {
        async fetchGroupSavingGoals() {
            try {
                const data = await fetchGroupSavingGoals()
                console.log('fetched data, ', data)
                this.groupSavingGoals = data.map((groupSavingGoal) => ({
                    ...groupSavingGoal,
                    title: groupSavingGoal.title,
                    owner: groupSavingGoal.owner,
                    invitedUsers: groupSavingGoal.invitedUsers,
                    totalProgress:
                        groupSavingGoal.groupSavingGoalRelations.reduce(
                            (acc, rel) => acc + rel.savingGoal.runningTotal,
                            0
                        ),
                    goalAmount: groupSavingGoal.groupSavingGoalRelations.reduce(
                        (acc, rel) => acc + rel.savingGoal.goalAmount,
                        0
                    ),
                }))
            } catch (error) {
                console.error('Failed to fetch groupSavingGoals:', error)
            }
        },
        /* could just fetch from backend, would be a better way to do it.

        async contributeToChallenge(challengeId, amount, savingGoalId) {
            const depositResponse = await depositGroupSavingAmount(savingGoalId, amount);
            if (depositResponse) {
                const submitResponse = await submitGroupChallengeEntry(challengeId);
                if (submitResponse) {
                    // Update the store state as necessary
                    this.updateLocalState(savingGoalId, submitResponse.newProgress);
                }
            }
        },
        updateLocalState(savingGoalId, newProgress) {
            // Find and update the specific saving goal within the group saving goal
            const groupIndex = this.groupSavingGoals.findIndex(group => group.groupSavingGoalRelations.some(rel => rel.savingGoal.savingId === savingGoalId));
            if (groupIndex !== -1) {
                const savingGoalIndex = this.groupSavingGoals[groupIndex].groupSavingGoalRelations.findIndex(rel => rel.savingGoal.savingId === savingGoalId);
                if (savingGoalIndex !== -1) {
                    this.groupSavingGoals[groupIndex].groupSavingGoalRelations[savingGoalIndex].savingGoal.runningTotal += newProgress;
                    // Additional logic to handle changes in the group saving goal
                }
            }
        }
         */
    },
})

import { defineStore } from 'pinia'
import { fetchSavingGoals } from '@/utils/SavingGoalService.js'
import { fetchGroupSavingGoals } from '@/utils/GroupSavingGoalService.js'

export const useSavingGoalStore = defineStore('savingGoals', {
    state: () => ({
        savingGoals: [],
        groupSavingGoals: [],
        user: [],
    }),
    getters: {},
    actions: {
        async fetchSavingGoals() {
            try {
                const data = await fetchSavingGoals()
                this.savingGoals = data.map((savingGoal) => ({
                    ...savingGoal,
                    savingId: savingGoal.savingId,
                    runningTotal: savingGoal.runningTotal,
                    name: savingGoal.name,
                    image: savingGoal.image,
                    goalAmount: savingGoal.goalAmount,
                    deadline: savingGoal.deadline,
                }))
            } catch (e) {
                console.log('error, ', e)
            }
        },
        findGroupSavingGoalById(id) {
            for (let i = 0; i < this.savingGoals.length; i++) {
                if (id === this.savingGoals[i].savingId)
                    return this.savingGoals[i]
            }
        },
        async fetchGroupSavingGoalsByRelation() {
            try {
                const groups = await fetchGroupSavingGoals()
                let extractedSavingGoals = []
                let extractedUsers = []
                for (const group of groups) {
                    for (const relation of group.groupSavingGoalRelations) {
                        // Directly push the savingGoal object with the necessary transformations
                        const savingGoal = {
                            ...relation.savingGoal,
                            savingId: relation.savingGoal.savingId,
                            runningTotal: relation.savingGoal.runningTotal,
                            name: relation.savingGoal.name,
                            image: relation.savingGoal.image,
                            goalAmount: relation.savingGoal.goalAmount,
                            deadline: relation.savingGoal.deadline,
                        }
                        const user = {
                            ...relation.user,
                            user: relation.user.name,
                        }
                        extractedSavingGoals.push(savingGoal)
                        extractedUsers.push(user)
                    }
                }
                this.groupSavingGoals = extractedSavingGoals
                this.user = extractedUsers
            } catch (error) {
                console.error('Failed to fetch saving goals:', error)
            }
        },
    },
})

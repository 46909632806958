<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue'

import SavingGoalComponent from '@/components/savinggoal/SavingGoalComponent.vue'
import { useGroupSavingGoalStore } from '@/stores/groupSavingGoalStore.js'
import { useTokenStore } from '@/stores/tokenStore.js'
import ProgressBarComponent from '@/components/generic/ProgressBarComponent.vue'
import ChallengesListComponent from '@/components/challenge/ChallengesListComponent.vue'

const props = defineProps({
    groupSavingGoalId: Number,
})

const store = useGroupSavingGoalStore()
const tokenStore = useTokenStore()

const groupSavingGoals = ref(store.groupSavingGoals)

onMounted(async () => {
    if (store.groupSavingGoals.length === 0) {
        groupSavingGoals.value = null
        await store.fetchGroupSavingGoals()
    }
})

watchEffect(() => {})

const isDataLoaded = computed(() => !!groupSavingGoal.value)

const groupSavingGoal = computed(() => {
    const groupSavingGoal = store.groupSavingGoals.find(
        (gs) => gs.groupSavingGoalId === props.groupSavingGoalId
    )
    return groupSavingGoal || null
})

const userGoals = computed(() => {
    return isDataLoaded.value
        ? groupSavingGoal.value.groupSavingGoalRelations.filter(
              (relation) => relation.user.email === tokenStore.email
          )
        : []
})

const otherGoals = computed(() => {
    return isDataLoaded.value
        ? groupSavingGoal.value.groupSavingGoalRelations.filter(
              (relation) => relation.user.email !== tokenStore.email
          )
        : []
})

const invitedUsers = computed(() => {
    return isDataLoaded.value
        ? groupSavingGoal.value.invitedUsers
              .map((user) => user.email)
              .join(', ')
        : ''
})
</script>

<template>
    <!-- NEEDS MORE STYLING! -->
    <div v-if="isDataLoaded" class="text-sky">
        <h2>{{ groupSavingGoal.title }}</h2>
        <div class="text-sm text-sky flex flex-col">
            <p>Spart opp: {{ groupSavingGoal.totalProgress }}</p>
            <p>Mål: {{ groupSavingGoal.goalAmount }}</p>
        </div>
        <div v-for="(relation, i) in userGoals" :key="i" class="my-3">
            <SavingGoalComponent
                :saving-id="relation.savingGoal.savingId"
                :email="relation.user.email"
                :type="'GROUP'"
            />
        </div>
        <hr class="border-gray-300" />
        <div v-for="(relation, i) in otherGoals" :key="i" class="text-sm my-2">
            <SavingGoalComponent
                :saving-id="relation.savingGoal.savingId"
                :email="relation.user.email"
                :type="'GROUP'"
            />
            <hr v-if="i !== otherGoals.length - 1" class="border-gray-700" />
        </div>
        <p>Eier: {{ groupSavingGoal.owner.email }}</p>
        <p>Inviterte: {{ invitedUsers }}</p>
    </div>
    <div v-else>Laster inn...</div>
</template>

<style scoped></style>

<script setup>
import { ref } from 'vue'
import SettingComponent from '../components/SettingComponent.vue'

const isDarkMode = ref(false)
const fontSize = ref('medium')

const fontSizes = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
]

const toggleDarkMode = () => {
    isDarkMode.value = !isDarkMode.value
}

const colorScheme = ref('default')

const colorOptions = [
    { value: 'default', label: 'Default' },
    { value: 'dark', label: 'Dark' },
]

const booleanSetting1 = ref(false)
const booleanSetting2 = ref(false)
</script>

<template>
    <div class="absolute mx-auto">
        <h1 class="text-sky text-2xl font-bold mb-4">Settings</h1>
        <SettingComponent
            class=""
            label="Dark Mode"
            type="toggle"
            v-model:modelValue="isDarkMode"
        />
        <SettingComponent
            label="Font Size"
            type="select"
            :options="fontSizes"
            v-model:modelValue="fontSize"
        />
        <SettingComponent
            label="Color Scheme"
            type="select"
            :options="colorOptions"
            v-model:modelValue="colorScheme"
        />
        <SettingComponent
            label="Boolean Setting 1"
            type="toggle"
            v-model:modelValue="booleanSetting1"
        />
        <SettingComponent
            label="Boolean Setting 2"
            type="toggle"
            v-model:modelValue="booleanSetting2"
        />
    </div>
</template>

<style scoped></style>

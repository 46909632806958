<script setup>
import { useChallengesStore } from '@/stores/challengeStore.js'
import { useSavingGoalStore } from '@/stores/savingGoalStore.js'

const store = useChallengesStore()
const savingGoalStore = useSavingGoalStore()

const props = defineProps({
    emoji: String,
    amount: Number,
    savingChallengeId: Number,
    savingGoalId: Number,
    doneForToday: Boolean,
})

const handleButtonClick = async () => {
    if (props.savingChallengeId && props.savingGoalId) {
        await store.depositChallengeAmount(
            props.savingGoalId,
            props.amount
        )
        const newProgress = await store.submitEntry(props.savingChallengeId)

        if (newProgress !== undefined) {
            await savingGoalStore.fetchSavingGoals()
            await store.fetchChallenges()
        }
    }
}
</script>

<template>
    <div class="flex place-items-baseline">
        <div v-show="!doneForToday" class="relative">
            <button
                class="active:scale-90 rounded-2xl bg-sky text-white text-xl px-4 py-4 shadow-lg"
                @click="handleButtonClick"
            >
                {{ props.emoji }}
            </button>
            <p class="absolute top-0 right-0 mx-2">{{ props.amount }}</p>
        </div>
        <div v-show="doneForToday" class="relative">
            <button
                class="active:scale-90 rounded-2xl bg-sky text-white text-xl px-4 py-4 shadow-lg opacity-25"
            >
                {{ props.emoji }}
            </button>
            <p class="absolute top-0 right-0 mx-2">{{ props.amount }}</p>
        </div>
    </div>
</template>

<style scoped></style>
